import { TypeAnimation } from 'react-type-animation';
import { ChatProps } from '../../types/types';
import { convertUtcToSriLankanTime } from '../../utils/date_time';
import bot from '../../assets/bot.png';
import './chat-messages.scss';
import { Accordion } from 'react-bootstrap';

export const ChatMessages = ({ chatData, chatId }: ChatProps) => {
  return (
    <>
      {chatData.map((messages: any, index: number) => (
        <div key={index}>
          {messages.hasOwnProperty(chatId) ||
          messages.hasOwnProperty('newChat') ? (
            <div>
              <div className="row mb-3">
                <div className="col-12">
                  <div className="chat-view-user-div">
                    <div className="chat-view-user">
                      <div className="chat-view-user-msg-text">
                        <p className="data_test_animation" key={index}>
                          {messages[chatId]['question']}
                        </p>
                      </div>
                    </div>
                    <div className="chat-view-user-time">
                      {convertUtcToSriLankanTime(
                        messages[chatId]['question_time']
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {messages[chatId]['answer'] ? (
                <div className="row mb-3">
                  <div className="col-1">
                    <img
                      style={{ width: '40px', alignSelf: 'flex-end' }}
                      src={bot}
                      alt="bot"
                    />
                  </div>

                  <div className="col-11">
                    <div className="chat-view-bot-div">
                      <div className="chat-view-bot">
                        {/* <div className="chat-view-bot-msg-text">
                                            <p className='data_test_animation' key={index}></p>
                                            <TypeAnimation
                                              sequence={messages[chatId]["answer"]}
                                              style={{ whiteSpace: 'pre-wrap' }}
                                              speed={95}
                                              repeat={0}
                                              cursor={false}
                                            />
                                          </div> */}
                        <div>
                          {!messages.data_animation ? (
                            <div className="chat-view-bot-msg-text">
                              <p className="data_test_animation" key={index}>
                                {messages[chatId]['answer']}
                              </p>

             {  messages[chatId]['citations'] &&                <Accordion>
      <Accordion.Item eventKey={index.toString()}>
        <Accordion.Header>View Citations</Accordion.Header>
        <Accordion.Body>
          {messages[chatId]['citations'].map((citate:any)=>(
        <>  <a href={citate.extra.path}>{citate.name}  - page {citate.page}</a><br/></>
          ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>}
                            </div>
                          ) : (
                            <div className="chat-view-bot-msg-text">
                              <p
                                className="data_test_animation"
                                key={index}
                              ></p>
                              <TypeAnimation
                                sequence={messages[chatId]['answer']}
                                style={{ whiteSpace: 'pre-wrap' }}
                                speed={95}
                                repeat={0}
                                cursor={false}
                              />

{  messages[chatId]['citations'] &&                <Accordion>
      <Accordion.Item eventKey={index.toString()}>
        <Accordion.Header>View Citations</Accordion.Header>
        <Accordion.Body>
          {messages[chatId]['citations'].map((citate:any)=>(
        <>  <a href={citate.extra.path}>{citate.name} - page {citate.page}</a><br/></>
          ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="chat-view-bot-time">
                        {convertUtcToSriLankanTime(
                          messages[chatId]['answer_time']
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <> </>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      ))}
    </>
  );
};
